import * as React from 'react'
import styled from 'styled-components'

import { mediaqueries, Headings, ISpecialist } from '@slipway/theme-components'

import { useI18n } from '@bestyled/primitives'
import { IArticle } from '../../types'

import ArticlesList from '../articles/Articles.List'

interface SpecialistArticlesProps {
  articles: IArticle[]
  specialist: ISpecialist
}

const SpecialistArticles: React.FC<SpecialistArticlesProps> = ({
  specialist,
  articles
}) => {
  const { t } = useI18n()
  return articles.length > 0 ? (
    <SpecialistArticlesContainer>
      <ArticleSectionHeader>
        {t(`Slipway.Notes authored by`)} {specialist.name}
      </ArticleSectionHeader>
      <ArticlesList articles={articles} alwaysShowAllDetails />
    </SpecialistArticlesContainer>
  ) : null
}

export default SpecialistArticles

const SpecialistArticlesContainer = styled.div`
  background: linear-gradient(
    180deg,
    ${(p) => p.theme.colors.card} 0%,
    rgba(249, 250, 252, 0) 91.01%
  );
  border-radius: 8px;
  padding: 88px 98px;
  position: relative;
  z-index: 1;

  ${mediaqueries.desktop_medium`
    padding: 80px;
  `}

  ${mediaqueries.desktop`
    padding: 0;
    background: transparent;
  `}
`

const ArticleSectionHeader = styled(Headings.h3 as React.FC<{}>)`
  font-family: ${(p) => p.theme.fonts.sans};
  color: ${(p) => p.theme.colors.brand};
  margin-bottom: 35px;
  margin-top: 35px;

  ${mediaqueries.desktop`
    margin-bottom: 15px;
  `}

  ${mediaqueries.phablet`
    font-size: 22px;  
    padding: 0 0 0;
    margin-bottom: 10px;
    text-align: center;
  `}
`
