import * as React from 'react'
import styled from 'styled-components'

import { Section } from '@slipway/theme-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Paginator from '../components/Navigation.Paginator'

import SpecialistHero from '../sections/specialist/Specialist.Hero'
import SpecialistArticles from '../sections/specialist/Specialist.Articles'

import { Template, IArticle } from '../types'

const ArticlesPage: Template = ({ location, pageContext }) => {
  const { specialist } = pageContext.additionalContext
  const articles = (pageContext.group as unknown[]).filter(
    (x: any) => !x.hidden
  ) as IArticle[]

  return (
    <Layout
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
    >
      <SEO
        pathname={location.pathname}
        title={specialist.name}
        description={specialist.title}
      />
      <Section narrow>
        <SpecialistHero specialist={specialist} location={location} />
        <SpecialistArticles specialist={specialist} articles={articles} />
        <SpecialistPaginator>
          <Paginator {...(pageContext as any)} />
        </SpecialistPaginator>
      </Section>
      <SpecialistsGradient />
    </Layout>
  )
}

export default ArticlesPage

const SpecialistsGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${(p) => p.theme.colors.gradient};
  transition: ${(p) => p.theme.colorModeTransition};
`

const SpecialistPaginator = styled.div`
  text-align: center;
`
